class AvOurStore {
    constructor() {
        this.init();
    }

    init() {
        // Initialize pagination for each carousel after slide transition
        document.querySelectorAll('.av-our-store__carousel').forEach(carousel => {
            carousel.addEventListener('slid.bs.carousel', () => this.pagination(carousel));
            // Update pagination on initialization
            this.pagination(carousel);
        });
    }

    countSlides(carousel) {
        let slides = carousel.querySelectorAll('.carousel-item');
        return slides.length;
    }

    currentSlide(carousel) {
        let slides = carousel.querySelectorAll('.carousel-item');
        let active = carousel.querySelector('.carousel-item.active');
        return Array.prototype.indexOf.call(slides, active) + 1;
    }

    pagination(carousel) {
        const slidePagination = carousel.querySelector('.av-our-store__carousel__indicator__slide-pagination span');
        if (slidePagination) {
            slidePagination.innerHTML = `${this.currentSlide(carousel)}/${this.countSlides(carousel)}`;
        }
    }
}

export default AvOurStore;
