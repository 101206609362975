
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";

class AvDatePicker {
    constructor() {
        document.addEventListener('DOMContentLoaded', () => {
            this.init();
        });
    }

    init() {
        this.getElements();
    }

    getElements() {
        this.elements = document.querySelectorAll('.av-date-picker');

        if (this.elements.length > 0) {
            this.elements.forEach((element) => {
                this.initDatePicker(element);
            });
        }
    }

    initDatePicker(datePicker) {
        flatpickr(datePicker, {
            dateFormat: "d-m-Y",
        });
    }
}

export default AvDatePicker;
