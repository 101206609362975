class AvScrollingBox {
    constructor() {
        document.addEventListener('DOMContentLoaded', () => {
            this.init();
        });
    }

    init() {
        this.getElements();
    }

    getElements() {
        this.elements = document.querySelectorAll('.av-scrolling-box');

        if (this.elements.length > 0) {
            this.elements.forEach((element) => {
                this.initScrollingBox(element);
            });
        }
    }

    initScrollingBox(scrollingBox) {
        const scrollingBoxContent = scrollingBox.querySelector(
            '.av-scrolling-box__content'
        );

        // Check if scrollingBoxContent exists
        if (!scrollingBoxContent) {
            return;
        }

        const handleScroll = () => {
            const threshold = 100;
            const scrollingBoxTop = scrollingBox.getBoundingClientRect().top - threshold;

            if (scrollingBoxTop < 0) {
                scrollingBoxContent.classList.add('av-scrolling-box__content--sticky');
            } else {
                scrollingBoxContent.classList.remove('av-scrolling-box__content--sticky');
            }
        };

        window.addEventListener('scroll', handleScroll);

        // If you need to remove the event listener later:
        // window.removeEventListener('scroll', handleScroll);
    }
}

export default AvScrollingBox;
