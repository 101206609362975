class AvParallaxScrolling {
    elements = [];

    constructor() {
        this.init();
    }

    init() {
        this.elements = document.querySelectorAll('.av-parallax-scrolling');

        if (this.elements.length > 0) {
            this.elements.forEach(element => {
                this.processItems(element);
                this.addScrollEvent(element);
            });
        }
    }

    processItems(element) {
        const items = element.querySelectorAll('.av-parallax-scrolling__item');

        if (items.length > 0) {
            let oldHeight = 0;
            items.forEach((item, index) => {
                const itemHeight = item.offsetHeight;
                const bg = item.querySelector('.av-parallax-scrolling__item__bg');

                bg.style.transform = `translateY(-${oldHeight}px)`;
                bg.style.height = `${item.offsetHeight + 300}px`;
                // bg.style.transition = `transform 0.2s linear ${index * 0.2}s`;

                oldHeight += item.offsetHeight;
            });
        }
    }

    addScrollEvent(element) {
        const items = element.querySelectorAll('.av-parallax-scrolling__item');


        window.addEventListener('scroll', () => {
            const scrollPosition = window.scrollY;

            items.forEach((item, index) => {
                const bg = item.querySelector('.av-parallax-scrolling__item__bg');
                const currentOffsetTop = item.offsetTop;

                bg.style.height = `${item.offsetHeight + 300}px`;
                bg.style.transform = `translateY(${ (scrollPosition - currentOffsetTop) * 0.234375 }px)`;
            });
        });
    }
}

export default AvParallaxScrolling;
