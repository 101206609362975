class Navbar {
    element = [];
    constructor() {
        this.init();
    }

    init() {
        this.getElement();

        // If navbar element not found
        if (!this.element) {
            return;
        }

        this.bindEvent();
    }

    // Get navbar element
    getElement() {
        this.element = document.querySelector('.navbar');
    }

    // Binding event
    bindEvent() {
        // Save current scroll position
        let lastScrollTop = 0;
        window.addEventListener('scroll', (event) => {
            // Save current scroll position
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            // If scroll down
            if (scrollTop > lastScrollTop && scrollTop > this.element.offsetHeight) {
                this.element.classList.add('av-navbar--hidden');
            }

            // If scroll up or scrollTop is less than navbar height
            else {
                this.element.classList.remove('av-navbar--hidden');
            }

            // Save current scroll position
            lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
        });
    }

}

export default Navbar;
