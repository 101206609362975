import LazyLoad from "vanilla-lazyload";

class AvLazyloadImages {
    constructor() {
        this.init();
    }

    init() {
        new LazyLoad({
            elements_selector: ".av-lazyload-image",
        });
    }
}

export default AvLazyloadImages;
