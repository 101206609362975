// Require static images
function importAllImages(r) {
    return r.keys().map(r);
}

importAllImages(
    require.context('./assets/images', true, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/)
);

import 'jquery';
import 'bootstrap';
import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
import "@fortawesome/fontawesome-free/scss/solid.scss";
import "@fortawesome/fontawesome-free/scss/brands.scss";
import "@fortawesome/fontawesome-free/scss/regular.scss";

import "./scss/styles.scss";

import AvLazyloadImages from "./components/av-lazyload-images";
import AvSmoothScroll from "./components/av-smooth-scroll";
import Navbar from './components/navbar';
import AvScrollingBox from './components/av-scrolling-box';
import AvDatePicker from './components/av-date-picker';
import AvParallaxScrolling from './components/av-parallax-scrolling';
import AvOurStore from './components/av-our-store';


class App {
    constructor() {
        this.init();
    }

    init() {
        new AvLazyloadImages();
        new AvSmoothScroll();
        new Navbar();
        new AvScrollingBox();
        new AvDatePicker();
        new AvParallaxScrolling();
        new AvOurStore();
    }
}

new App();
